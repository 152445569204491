import React, { useState } from 'react'
import minusIcon from '../../assets/home/faqMinus.png'
import plusIcon from '../../assets/home/faqPlus.png'
import questionBg from '../../assets/home/questionBg.png'

const qna = [
  {
    question: 'Is there a money-back guarantee?',
    answer:
      `Yes! We offer a 30-day money-back guarantee. If you're not satisfied within the first 30 days, we’ll refund your payment—no questions asked.`
  },
  {
    question: 'Can I change my plan later?',
    answer:
      `Absolutely. You can upgrade or downgrade your plan at any time, ensuring that you always have the right level of service for your clinic's needs.`
  },
  {
    question: 'What is your cancellation policy?',
    answer:
      'You can cancel your subscription at any time. Your account will remain active until the end of the current billing cycle.'
  },
  {
    question: 'What support is available?',
    answer:
     `We provide comprehensive onboarding and ongoing support via chat, email, and phone. Our team is also available during business hours to assist with any specific questions or issues you may encounter.`
  }
]

const Faq = () => {
  const [openIndex, setOpenIndex] = useState(0)

  const handleToggle = index => {
    setOpenIndex(openIndex === index ? null : index)
  }

  return (
    <div className='relative py-20 mx-6 xl:mx-0' id='faqs'>
      {/* FAQ Heading */}
      <h2
        className='text-[26px] md:text-[30px] lg:text-[44px] xl:text-[48px] 3xl:text-[58px] font-[600] text-[#0B487F] mb-4 text-center'
        data-aos='fade-up'
      >
        Frequently asked questions
      </h2>

      {/* FAQ Questions and Answers */}
      <div
        className=' w-[88vw] 3xl:w-[75vw] mx-auto pt-20'
        style={{
          backgroundImage: `url(${questionBg})`,
          backgroundSize: '250px',
          backgroundPosition: 'right bottom',
          backgroundRepeat: 'no-repeat'
        }}
      >
        {qna.map((item, index) => (
          <div
            key={index}
            className='flex flex-col lg:flex-row items-start gap-4 mb-6'
            data-aos='fade-up'
          >
            {/* Question */}
            <div
              className={`flex justify-between items-center w-full lg:w-1/2 p-8 rounded-[16px] cursor-pointer ${
                openIndex === index ? 'bg-[#004085] text-white' : 'bg-gray-100'
              }`}
              onClick={() => handleToggle(index)}
            >
              <div className='flex items-center gap-4'>
                <img
                  src={openIndex === index ? minusIcon : plusIcon}
                  alt='Toggle Icon'
                  className='w-[24px]'
                />
                <p className='text-[18px] font-[500]'>{item.question}</p>
              </div>
            </div>

            {/* Answer */}
            {openIndex === index && (
              <div className='lg:w-1/2 bg-white p-4 rounded-xl shadow-lg relative'>
                <div className='absolute left-[-20px] top-6 w-0 h-0 border-t-[20px] border-t-transparent border-b-[20px] border-b-transparent border-r-[20px] border-r-white'></div>
                <p className='text-gray-600'>{item.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Faq
