import React from 'react'
import background from '../../assets/blogs/blogBackground.svg'

const DetailsHeader = () => {
  return (
    <div
      className='text-center overflow-hidden mt-[-5rem] flex flex-col justify-center items-center z-10 bg-[#D7ECFF]  absolute w-[100vw] z-5  pt-[12rem] pb-20 sm:pb-80  bg-no-repeat object-fill bg-cover  rounded-b-[64px]'
      style={{
        backgroundImage: `url(${background})`,
        backgroundPosition: 'center'
      }}
    >

      {/* Main Heading */}
      <h1
        className='text-[24px] md:text-[36px] xl:text-[58px] font-[600] text-[#0B487F] mb-4'
        data-aos='fade-up'
      >
        Hospital Management Systems <br /> are Transforming
      </h1>

      {/* Published Date */}
      <p
        className='text-[#0B487F] text-[14px] md:text-[16px] font-[600] mb-4'
        data-aos='fade-up'
      >
        Published 20 Jan 2022
      </p>

      {/* Subheading */}
      <p
        className='text-gray-500 hidden text-[16px] md:text-[20px] font-[400] text-center mx-auto mb-8'
        data-aos='fade-up'
      >
        How do you create compelling presentations that wow your colleagues and
        impress your managers?
      </p>

      {/* Tags */}
      <div className='flex justify-center gap-3 mb-10' data-aos='fade-up'>
        <span className='bg-[#F5F9FF] text-[#006ADD] px-3 py-1 rounded-full text-[14px] md:text-[16px] font-medium'>
          Design
        </span>
        <span className='bg-[#EEF4FF] text-[#0A487F] px-3 py-1 rounded-full text-[14px] md:text-[16px] font-medium'>
          Research
        </span>
        <span className='bg-[#FDF2FA] text-[#F9915D] px-3 py-1 rounded-full text-[14px] md:text-[16px] font-medium'>
          Presentation
        </span>
      </div>
    </div>
  )
}

export default DetailsHeader
