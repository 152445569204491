export const BlogTitle = ({ texts }) => {
    return (
      <div className='absolute top-4 left-4 flex flex-col text-black'>
        {texts.map((text, index) => {
          
          const baseClasses = 'text-[12px] sm:text-[14px] xl:text-[18px] font-semibold pt-1 pb-2 px-2 bg-white';
  
          
          const hasIndexTwo = texts.length > 2;
          
          const classNames = index === 0
            ? `${baseClasses} rounded-tr-xl rounded-br-xl rounded-tl-lg w-full pr-4`
            : index === 1
            ? `${baseClasses} custom-span-sm rounded-br-xl pr-4 w-[55%] ${!hasIndexTwo ? 'rounded-bl-lg' : ''}`
            : `${baseClasses} custom-span-sm rounded-br-xl pr-4 w-[75%]`;
  
          return (
            <p><span key={index} className={classNames}>
              {text}
            </span>
            </p>
          );
        })}
      </div>
    );
  };
  