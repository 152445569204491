import React from 'react'
import BlogAuthor from './BlogAuthor'

import firstImg from '../../assets/blogHeader/firstImg.png'
import imgLink from '../../assets/blogHeader/imgLink.png'
import secondImg from '../../assets/blogHeader/secondImg.png'
import thirdImg from '../../assets/blogHeader/thirdImg.png'

const BlogContent = () => {
  return (
    <div className='relative z-10  top-96 pt-16 xsm:pt-4 sm:pt-32 pb-96'>
      {/* First Image */}
      <div
        className='relative mb-8 w-[90vw] lg:max-w-[82.5vw] mx-auto z-10'
        data-aos='fade-up'
      >
        <img
          src={firstImg}
          alt='Header'
          className='w-full rounded-[20px] z-10 relative'
        />
      </div>

      {/* Text Sections */}
      <div className='relative z-10 space-y-8  w-[90vw] lg:max-w-[82.5vw]  mx-auto'>
        {/* Introduction Section */}
        <div className='text-start' data-aos='fade-up'>
          <h2 className='text-[24px] md:text-[30px] font-bold text-[#101828] mb-4'>
            Introduction
          </h2>
          <p className='text-[#475467] text-[16px] md:text-[18px] text-justify'>
            Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam
            suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum
            quis montes, sit sit. Tellus aliquam enim urna, etiam. Mauris
            posuere vulputate arcu amet, vitae nisi, tellus tincidunt. At
            feugiat sapien varius id. <br />
            <br /> Eget quis mi enim, leo lacinia pharetra, semper. Eget in
            volutpat mollis at volutpat lectus velit, sed auctor. Porttitor
            fames arcu quis fusce augue enim. Quis at habitant diam at. Suscipit
            tristique risus, at donec. In turpis vel et quam imperdiet. Ipsum
            molestie aliquet sodales id est ac volutpat.
          </p>
        </div>

        {/* Second Image and Paragraph */}
        <div
          className='relative flex-col gap-2 flex justify-start items-start mb-8'
          data-aos='fade-up'
        >
          <img
            src={secondImg}
            alt='Teamwork'
            className='w-full h-[320px] md:h-[450px] xl:h-[700px] 3xl:h-[800px] rounded-[32px] z-10'
          />
          <div className='flex justify-start items-start gap-2'>
            <img src={imgLink} alt='Image courtesy of Fauxels via Pexels' />
            <span className='text-[#475467] text-[14px]'>
              Image courtesy of Fauxels via Pexels
            </span>
          </div>
        </div>

        <p
          className='text-[#475467] text-[16px] md:text-[18px] text-justify'
          data-aos='fade-up'
        >
          Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris
          id. Non pellentesque congue eget consectetur turpis. Sapien, dictum
          molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis
          velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh
          orci.
        </p>

        {/* Quote Section */}
        <blockquote
          className='border-l-4 border-[#006ADD] pl-6 italic text-[20px] md:text-[24px] text-[500] font-medium text-[#101828] py-4 leading-[36px]'
          data-aos='fade-up'
        >
          “In a world older and more complete than ours they move finished and
          complete, gifted with extensions of the senses we have lost or never
          attained, living by voices we shall never hear.”
          <footer className='mt-4'>
            <p className='text-[#475467] font-normal text-[16px]'>
              — Olivia Rhye, Product Designer
            </p>
          </footer>
        </blockquote>

        <p
          className='text-[#475467] text-[16px] md:text-[18px] text-justify'
          data-aos='fade-up'
        >
          Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla
          odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis
          mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla.{' '}
          <br />
          <br />
          Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet
          commodo consectetur convallis risus. Sed condimentum enim dignissim
          adipiscing faucibus consequat, urna. Viverra purus et erat auctor
          aliquam. Risus, volutpat vulputate posuere purus sit congue convallis
          aliquet. Arcu id augue ut feugiat donec porttitor neque. Mauris, neque
          ultricies eu vestibulum, bibendum quam lorem id. Dolor lacus, eget
          nunc lectus in tellus, pharetra, porttitor. <br />
          <br /> Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim
          mauris id. Non pellentesque congue eget consectetur turpis. Sapien,
          dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus.
          Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam
          nibh orci.
        </p>

        {/* Software and Tools Section */}
        <div data-aos='fade-up'>
          <h2 className='text-[24px] font-[600] text-[#101828] mb-4'>
            Software and Tools
          </h2>
          <p className='text-[#475467] text-[16px] md:text-[18px] text-justify'>
            Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam
            suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum
            quis montes, sit sit. Tellus aliquam enim urna, etiam. Mauris
            posuere vulputate arcu amet, vitae nisi, tellus tincidunt. At
            feugiat sapien varius id. <br />
            <br /> Eget quis mi enim, leo lacinia pharetra, semper. Eget in
            volutpat mollis at volutpat lectus velit, sed auctor. Porttitor
            fames arcu quis fusce augue enim. Quis at habitant diam at. Suscipit
            tristique risus, at donec. In turpis vel et quam imperdiet. Ipsum
            molestie aliquet sodales id est ac volutpat.
          </p>
        </div>

        <div data-aos='fade-up'>
          <h2 className='text-[24px] font-[600] text-[#101828] mb-4'>
            Other Resources
          </h2>
          <p className='text-[#475467] text-[16px] md:text-[18px] text-justify'>
            Sagittis et eu at elementum, quis in. Proin praesent volutpat
            egestas sociis sit lorem nunc nunc sit. Eget diam curabitur mi ac.
            Auctor rutrum lacus malesuada massa ornare et. Vulputate consectetur
            ac ultrices at diam dui eget fringilla tincidunt. Arcu sit dignissim
            massa erat cursus vulputate gravida id. Sed quis auctor vulputate
            hac elementum gravida cursus dis.
          </p>
          <ol className='text-[#475467] text-[16px] md:text-[18px] mt-4 list-decimal pl-8'>
            <li className='mb-2'>
              Lectus id duis vitae porttitor enim gravida morbi.
            </li>
            <li className='mb-2'>
              Eu turpis posuere semper feugiat volutpat elit, ultrices
              suspendisse. Auctor vel in vitae placerat.
            </li>
            <li className='mb-2'>
              Suspendisse maecenas ac donec scelerisque diam sed est duis purus.
            </li>
          </ol>
        </div>

        {/* Third Image */}
        <div
          className='relative flex-col gap-2 flex justify-start items-start mb-8'
          data-aos='fade-up'
        >
          <img
            src={thirdImg}
            alt='Teamwork'
            className='w-full rounded-[32px] z-10'
          />
          <div className='flex justify-start items-center gap-2'>
            <img src={imgLink} alt='Image courtesy of Fauxels via Pexels' />
            <span className='text-[#475467] text-[14px]'>
              Image courtesy of Michael Burrows via Pexels
            </span>
          </div>
        </div>

        <p
          className='text-[#475467] text-[16px] md:text-[18px] text-justify'
          data-aos='fade-up'
        >
          Lectus leo massa amet posuere. Malesuada mattis non convallis quisque.
          Libero sit et imperdiet bibendum quisque dictum vestibulum in non.
          Pretium ultricies tempor non est diam. Enim ut enim amet amet integer
          cursus. Sit ac commodo pretium sed etiam turpis suspendisse at. <br />
          <br />
          Tristique odio senectus nam posuere ornare leo metus, ultricies.
          Blandit duis ultricies vulputate morbi feugiat cras placerat elit.
          Aliquam tellus lorem sed ac. Montes, sed mattis pellentesque suscipit
          accumsan. Cursus viverra aenean magna risus elementum faucibus
          molestie pellentesque. Arcu ultricies sed mauris vestibulum.
        </p>

        {/* Conclusion Section */}
        <div className='relative bg-[#F9FAFB] p-[32px] rounded-[24px] xl:rounded-[32px]' data-aos='fade-up'>
          <h2 className='text-[20px] font-bold text-[#101828] mb-4'>
            Conclusion
          </h2>
          <p className='text-[#475467] text-[16px] md:text-[18px] text-justify'>
            Morbi sed imperdiet in ipsum, adipiscing elit dui lectus. Tellus id
            scelerisque est ultricies ultricies. Duis est sit sed leo nisl,
            blandit elit sagittis. Quisque tristique consequat quam sed. Nisl at
            scelerisque amet nulla purus habitasse. <br />
            <br />
            Nunc sed faucibus bibendum feugiat sed interdum. Ipsum egestas
            condimentum mi massa. In tincidunt pharetra consectetur sed duis
            facilisis metus. Etiam egestas in nec sed et. Quis lobortis at sit
            dictum eget nibh tortor commodo cursus.
            <br />
            <br />
            Odio felis sagittis, morbi feugiat tortor vitae feugiat fusce
            aliquet. Nam elementum urna nisi aliquet erat dolor enim. Ornare id
            morbi eget ipsum. Aliquam senectus neque ut id eget consectetur
            dictum. Donec posuere pharetra odio consequat scelerisque et, nunc
            tortor.
          </p>
        </div>
        <BlogAuthor />
      </div>
    </div>
  )
}

export default BlogContent
