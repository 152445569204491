import React from 'react';
import { useMediaQuery } from 'react-responsive'; // Import the useMediaQuery hook
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick'; // Import the slider component
import blog4 from '../../assets/blogs/blog4Image.png';
import blog2 from '../../assets/home/blog2Image.jpeg';
import blog3 from '../../assets/home/blog3Image.jpeg';
import SingleRelatedCard from '../Blogs/SingleRelatedCard';

const relatedBlogs = [
  {
    id: 2,
    title: ['How Cutting-Edge Hospital', 'Management'],
    date: 'Sep 15, 2024',
    image: blog2,
  },
  {
    id: 3,
    title: ['How Cutting-Edge Hospital', 'Management'],
    date: 'Sep 15, 2024',
    image: blog4,
  },
  {
    id: 4,
    title: ['How Cutting-Edge Hospital', 'Management'],
    date: 'Sep 15, 2024',
    image: blog3,
  },
];

const LatestBlogs = () => {
  // Define a media query for mobile devices
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const navigate = useNavigate()

  // Slider settings
  const settings = {
    dots: true, // Show dots for navigation
    arrows: false,
    infinite: true, // Enable infinite loop
    speed: 500, // Transition speed
    slidesToShow: 1, // Show one slide at a time
    slidesToScroll: 1, // Scroll one slide at a time
  };

  return (
    <div className='relative w-[85vw] mx-auto pt-8 pb-20 z-20'>
      <div className='flex flex-col sm:flex-row justify-between items-start gap-4 sm:gap-0 mb-8'>
        <div data-aos='fade-up'>
          <h2 className='text-[24px] md:text-[30px] font-[700] text-[#0B487F]'>
            Latest blogs & Articles
          </h2>
          <p className='text-[#657C90] text-[16px] md:text-[20px] mt-5 font-[400] text-start mx-auto'>
           Navigating the Future of Clinic Management: Insights and Articles
          </p>
        </div>
        <button
          onClick={() => navigate('/blogs')}
          className='bg-[#F9915D] hover:bg-[#c76a3e] text-white py-3 px-8 rounded-[16px] text-[18px] font-[700] transition-all duration-300 ease-in-out transform hover:scale-105'
          data-aos='fade-up'
        >
          View All
        </button>
      </div>

      {/* Blog Cards Section */}
      {isMobile ? (
        // Render carousel for mobile view
        <Slider {...settings}>
          {relatedBlogs.map((blog, index) => (
            <div key={index} className='px-2'>
              <SingleRelatedCard blog={blog} />
            </div>
          ))}
        </Slider>
      ) : (
        // Render grid for larger screens
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 z-40'>
          {relatedBlogs.map((blog, index) => (
            <SingleRelatedCard key={index} blog={blog} />
          ))}
        </div>
      )}
    </div>
  );
};

export default LatestBlogs;
