export const scrollToSection = (e, sectionId, offset = 0) => {
    e.preventDefault();
    const target = document.getElementById(sectionId); 
    const elementPosition = target?.getBoundingClientRect().top; 
    if(elementPosition) {
        const offsetPosition = elementPosition + window.pageYOffset - offset; 
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth' 
        });
    }
  };