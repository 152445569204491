import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/nav/medikanLogo1.png';
import { scrollToSection } from '../../utils/scroll.ts';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const mobileMenuRef = useRef(null);
  const hamburgerButtonRef = useRef(null); 

  const navigate = useNavigate();

  const handleScrollNavigation = (e, sectionId, offset, page = '/') => {
    e.preventDefault();
    navigate(`${page}`);
    setTimeout(() => {
      scrollToSection(e, sectionId, offset);
    }, 100);
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target) &&
        hamburgerButtonRef.current &&
        !hamburgerButtonRef.current.contains(event.target) &&
        isOpen
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <nav className='sticky top-0 sm:top-7 z-50 bg-white py-4 shadow-md w-[100vw] sm:w-[90vw] mx-auto rounded-bl-[24px] rounded-br-[24px] sm:rounded-[24px]'>
      <div className='flex items-center justify-between px-4 md:px-6'>
        <div className='flex-shrink-0'>
          <Link to={'/'}>
            <img
              src={logo}
              alt='Medikan Logo'
              onClick={(e) => handleScrollNavigation(e, 'hero', 0)}
              className='w-[80px] sm:w-[160px] h-[15px] sm:h-[27.28px]'
            />
          </Link>
        </div>

        {/* Nav Links (Desktop) */}
        <div className='hidden lg:flex space-x-6 text-gray-600'>
          <a
            href='#about'
            onClick={(e) => handleScrollNavigation(e, 'about', 80)}
            className='text-[#616161] hover:text-[#F9915D] font-[500] text-[18px]'
          >
            About Us
          </a>
          <a
            href='#why'
            onClick={(e) => handleScrollNavigation(e, 'why', 80)}
            className='text-[#616161] hover:text-[#F9915D] font-[500] text-[18px]'
          >
            Why Us
          </a>
          <a
            href='#faqs'
            onClick={(e) => handleScrollNavigation(e, 'faqs', 30)}
            className='text-[#616161] hover:text-[#F9915D] font-[500] text-[18px]'
          >
            FAQs
          </a>
        </div>

        {/* Buttons (Desktop) */}
        <div className='hidden lg:flex space-x-4'>
          <a href='https://app.medikan.net' className='text-[16px] font-[700] text-[#F9915D] hover:text-white bg-[#FFF9F6] border-2 border-[#f9915d] hover:bg-[#F9915D] rounded-[16px] px-[24px] py-[8px]'>
            Log In
          </a>
          <a
            href='#book'
            onClick={(e) => handleScrollNavigation(e, 'book', 200)}
            className='relative text-[10px] sm:text-[16px] font-[500] sm:font-[700] text-white bg-[#F9915D] rounded-[12px] sm:rounded-[14px] px-[24px] py-[10px] sm:py-[12px] cursor-pointer transition-all duration-300 ease-in-out transform hover:bg-[#c76a3e] hover:scale-105'
          >
            Get started
          </a>
        </div>

        {/* Mobile hamburger button */}
        <div ref={hamburgerButtonRef} className='lg:hidden flex items-center'>
          <button onClick={toggleMenu}>
            <svg
              className={`w-6 h-6 text-gray-600 transform transition-transform duration-300 ${
                isOpen ? 'rotate-180' : ''
              }`}
              fill='none'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              {isOpen ? (
                <path d='M6 18L18 6M6 6l12 12' /> 
              ) : (
                <path d='M4 6h16M4 12h16M4 18h16' /> 
              )}
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        ref={mobileMenuRef}
        className={`fixed top-10 sm:top-0 right-0 w-3/4 h-full bg-white shadow-lg z-50 transform transition-transform duration-300 ease-in-out ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className='flex justify-end items-center px-4 pb-4 py-0 sm:py-4 border-b'>
        <button className='hidden sm:flex' onClick={toggleMenu}>
            <svg
              className={`w-6 h-6 text-gray-600 transform transition-transform duration-300 ${
                isOpen ? 'rotate-180' : ''
              }`}
              fill='none'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              {isOpen ? (
                <path d='M6 18L18 6M6 6l12 12' /> 
              ) : (
                <path d='M4 6h16M4 12h16M4 18h16' /> 
              )}
            </svg>
          </button>
        </div>

        <div className='flex flex-col space-y-6 p-4 text-gray-600'>
          <a
            href='#about'
            onClick={(e) => {
              handleScrollNavigation(e, 'about', 50);
              toggleMenu();
            }}
            className='text-[#616161] hover:text-[#F9915D] font-[500] text-[18px]'
          >
            About Us
          </a>
          <a
            href='#why'
            onClick={(e) => {
              handleScrollNavigation(e, 'why', 50);
              toggleMenu();
            }}
            className='text-[#616161] hover:text-[#F9915D] font-[500] text-[18px]'
          >
            Why Us
          </a>
          <a
            href='#faqs'
            onClick={(e) => {
              handleScrollNavigation(e, 'faqs', 30);
              toggleMenu();
            }}
            className='text-[#616161] hover:text-[#F9915D] font-[500] text-[18px]'
          >
            FAQs
          </a>

          {/* Buttons */}
          <a href='https://app.medikan.net' className='text-[16px] font-[700] text-[#F9915D] sm:hover:text-white bg-[#FFF9F6] border-2 border-[#f9915d] sm:hover:bg-[#F9915D] rounded-[16px] px-[24px] py-[8px] w-[250px]'>
            Log In
          </a>
          <a
            href='#book'
            onClick={(e) => {
              handleScrollNavigation(e, 'book', 100);
              toggleMenu();
            }}
            className='relative text-center text-[16px] rounded-[16px] px-[24px] py-[8px] w-[250px] sm:text-[16px] font-[500] sm:font-[700] text-white bg-[#F9915D] sm:rounded-[14px] sm:py-[12px] cursor-pointer transition-all duration-300 ease-in-out transform sm:hover:bg-[#c76a3e] sm:hover:scale-105'
          >
            Get started
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
