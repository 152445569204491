import React, { useState } from 'react';
import clinicImg1 from '../../assets/home/clinic1.png';
import clinicImg2 from '../../assets/home/clinic2.png';
import clinicImg3 from '../../assets/home/clinic3.png';
import clinicImg4 from '../../assets/home/clinic4.png';
import clinicImg5 from '../../assets/home/clinic5.png';

const ClinicPerformance = () => {
  const [hoveredCard, setHoveredCard] = useState(null);

  return (
    <div className='py-8'  id='why'>
      <h2 className='text-[26px] md:text-[30px] lg:text-[44px] xl:text-[54px] font-[600] text-[#0B487F] mb-8 mt-4 lg:mt-0 text-center' data-aos='fade-up'>
        Boost Your Clinic’s performance through Px
      </h2>
      <p className='text-gray-500 text-[18px] font-[400] leading-[22.5px] w-[70vw] text-center mx-auto' data-aos='fade-up'>
        Leverage patient experience (Px) insights to optimize clinic operations, improve care delivery, and drive measurable performance outcomes.
      </p>

      <div className='mt-8 px-6'>
        <div className='flex flex-col lg:flex-row justify-center items-center gap-6'>
          <div className='flex flex-col justify-center items-center gap-6'>
            <div className='flex justify-center flex-col md:flex-row items-center gap-6'>
              {[1, 2].map((card) => (
                <div
                  key={card}
                  className='relative w-[280px] xl:w-[350px] h-[300px] md:h-[335px] sm:h-[320px] lg:h-[300px] xl:h-[354px] rounded-[24px] xl:rounded-[32px] overflow-hidden perspective'
                  onMouseEnter={() => setHoveredCard(card)}
                  onClick={() => setHoveredCard(card)}
                  onMouseLeave={() => setHoveredCard(null)}
                  data-aos='fade-up'
                >
                  <div className={`flip-card ${hoveredCard === card ? 'flipped' : ''}`}>
                    <div className='flip-card-inner'>
                      <div className={`flip-card-front ${card === 1 ? 'bg-[#006ADD]' : 'bg-[#F9915D]'} flex flex-col justify-center items-center rounded-[24px] p-[24px] space-y-6`}>
                        <img src={card === 1 ? clinicImg1 : clinicImg2} alt={`Card ${card}`} className='rounded-[16px]' />
                        <p className='text-md xl:text-xl font-[700] text-white'>
                          {card === 1 ? 'Reduced Administrative Burden' : 'Higher Visibility'}
                        </p>
                      </div>
                      <div className={`flip-card-back ${card === 1 ? 'bg-[#006ADD]' : 'bg-[#F9915D]'} flex items-center justify-center rounded-[24px] p-[16px] text-white`}>
                        <p className='text-lg leading-[1.4] font-[400] text-start'>
                          {card === 1 ? 'By streamlining workflows and automating repetitive tasks, healthcare teams can focus more on patient care rather than paperwork, leading to increased productivity and efficiency.'
                          : 'Implementing data-driven processes enhances transparency across operations, allowing healthcare providers to monitor performance metrics and identify areas for improvement.'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div
              className='relative w-[280px] md:w-[584px] xl:w-[724px] h-[300px] sm:h-[430px] lg:h-[475px] xl:h-[530px] rounded-[24px] xl:rounded-[30px] overflow-hidden perspective'
              data-aos='fade-up'
              onMouseEnter={() => setHoveredCard(3)}
              onClick={() => setHoveredCard(3)}
              onMouseLeave={() => setHoveredCard(null)}
            >
              <div className={`flip-card ${hoveredCard === 3 ? 'flipped' : ''}`}>
                <div className='flip-card-inner'>
                  <div className='flip-card-front bg-[#D9E9FA] flex flex-col items-center justify-center rounded-[24px] p-[24px] space-y-6'>
                    <img src={clinicImg3} alt='Comprehensive Patient Management' className='rounded-[16px]' />
                    <p className='text-md xl:text-xl font-[700] text-black'>
                      Comprehensive Patient Management
                    </p>
                  </div>
                  <div className='flip-card-back bg-[#D9E9FA] flex items-center justify-center rounded-[24px] p-[16px] text-black'>
                    <p className='text-lg leading-[1.4] font-[400] text-start'>
                    Utilizing patient-centric operations ensures that all aspects of patient care are coordinated and tailored, resulting in better management of patient journeys and outcomes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='flex flex-col md:flex-row lg:flex-col justify-center items-center gap-6'>
          <div className='relative w-[280px] sm:w-[280px] lg:w-[350px] xl:w-[500px] h-[300px] sm:h-[350px] lg:h-[420px] xl:h-[530px] rounded-[16px] xl:rounded-[32px] overflow-hidden perspective' data-aos='fade-up' onMouseEnter={() => setHoveredCard(4)} onClick={() => setHoveredCard(4)}
          onMouseLeave={() => setHoveredCard(null)}>
          <div className={`flip-card ${hoveredCard === 4 ? 'flipped' : ''}`}>
            <div className='flip-card-inner'>
              <div className='flip-card-front bg-[#FDDDCD] flex flex-col items-center justify-center rounded-[16px] sm:rounded-[24px] p-[16px] sm:p-[24px] space-y-4 sm:space-y-6'>
                <img src={clinicImg5} alt='Patient Satisfaction' className='rounded-[12px] sm:rounded-[16px] w-full h-auto max-h-[150px] sm:max-h-[80%] object-contain md:object-contain' />
                <p className='text-md xl:text-xl font-[700] text-black'>
                  Patient Satisfaction
                </p>
              </div>
              <div className='flip-card-back bg-[#FDDDCD] flex items-center justify-center rounded-[16px] sm:rounded-[24px] p-[12px] sm:p-[16px] text-black'>
                <p className='text-lg leading-[1.4] font-[400] text-start'>
                Proactive patient care fosters positive interactions, addressing needs before they arise, which leads to improved experiences and higher satisfaction rates.
                </p>
              </div>
            </div>
          </div>
        </div>


            <div
              className='relative w-[280px] lg:w-[350px] xl:w-[500px] h-[300px] md:h-[350px] xl:h-[350px] rounded-[24px] xl:rounded-[32px] overflow-hidden perspective'
              data-aos='fade-up'
              onMouseEnter={() => setHoveredCard(5)}
              onClick={() => setHoveredCard(5)}
              onMouseLeave={() => setHoveredCard(null)}
            >
              <div className={`flip-card ${hoveredCard === 5 ? 'flipped' : ''}`}>
                <div className='flip-card-inner'>
                  <div className='flip-card-front bg-[#63ABEC] flex flex-col items-center justify-center rounded-[24px] p-[24px] space-y-6'>
                    <img src={clinicImg4} alt='Increased Revenue, Reduced Cost' className='rounded-[16px]' />
                    <p className='text-md xl:text-xl font-[700] text-white'>
                    Increased Revenue, Reduced Cost
                    </p>
                  </div>
                  <div className='flip-card-back bg-[#63ABEC] flex items-center justify-center rounded-[24px] p-[16px] text-black'>
                    <p className='text-lg leading-[1.4] font-[400] text-start'>
                    Optimizing processes and enhancing patient experiences can lead to higher patient retention and acquisition, boosting revenue while minimizing unnecessary costs through efficiency improvements.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClinicPerformance;
