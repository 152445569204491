import React, { useState } from 'react';
import bottomLeftImg from '../../assets/home/DemoBottom.png';
import topRightImg from '../../assets/home/DemoTop.png';

const BookDemo = () => {
  const [formData, setFormData] = useState({
    name: '',
    clinicName: '',
    email: '',
    phone: '',
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const response = await fetch('send_email.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(formData).toString(),
    });

    const result = await response.text();
    console.log(result); // For debugging
    setIsSubmitted(true); // Update submission status
    setFormData({ name: '', clinicName: '', email: '', phone: '' }); // Clear form
  };

  return (
    <div className='w-full'>
      <div
        className='relative overflow-hidden w-[88vw] 3xl:h-[628px] 3xl:w-[75vw] bg-[#004085] rounded-[32px] p-8 lg:p-12 text-white mx-auto flex flex-col justify-center'
        id='book'
      >
        <img
          src={topRightImg}
          alt='Decorative Top Right'
          className='absolute top-0 right-0 w-[100px] md:w-[150px] lg:w-[200px] h-auto z-0'
        />
        <img
          src={bottomLeftImg}
          alt='Decorative Bottom Left'
          className='absolute bottom-0 left-0 w-[100px] md:w-[150px] lg:w-[200px] h-auto z-0'
        />
        
        {!isSubmitted ? (
          <>
            <div className='relative z-10'>
              <h2 className='text-[24px] lg:text-[32px] xl:text-[48px] 3xl:text-[56px] font-semibold text-center mb-2 sm:mb-4'>
                Book a Demo
              </h2>
              <p className='text-center text-[18px] xl:text-[20px] 3xl:text-[22px] mb-10'>
                Ready to streamline your clinic? Book your free demo now!
              </p>
            </div>

			<form className='grid grid-cols-1 md:grid-cols-2 gap-8 sm:gap-20 m-6 relative z-10' onSubmit={handleSubmit}>
			  {/* Form Fields */}
			  <input
				type='text'
				name='name'
				value={formData.name}
				onChange={handleChange}
				className='w-full bg-transparent border-b border-gray-300 outline-none focus:border-white text-white placeholder:text-white pb-5 pl-4' 
				placeholder='Your Name'
				required
			  />
			  <input
				type='text'
				name='clinicName'
				value={formData.clinicName}
				onChange={handleChange}
				className='w-full bg-transparent border-b border-gray-300 outline-none focus:border-white text-white placeholder:text-white pb-5 pl-4' 
				placeholder='Clinic Name'
				required
			  />
			  <input
				type='email'
				name='email'
				value={formData.email}
				onChange={handleChange}
				className='w-full bg-transparent border-b border-gray-300 outline-none focus:border-white text-white placeholder:text-white pb-5 pl-4' 
				placeholder='Your Email'
				required
			  />
			  <input
				type='text'
				name='phone'
				value={formData.phone}
				onChange={handleChange}
				className='w-full bg-transparent border-b border-gray-300 outline-none focus:border-white text-white placeholder:text-white pb-5 pl-4'
				placeholder='Phone Number'
				required
			  />
			  
			  {/* Button */}
			  <div className='text-center pt-4 relative z-10 col-span-1 md:col-span-2'>
				<button
				  type='submit'
				  className='bg-[#F9915D] hover:bg-[#c76a3e] text-white w-[130px] h-[40px] md:w-[150px] md:h-[50px] 3xl:w-[200px] 3xl:h-[58px] rounded-[16px] text-[16px] font-[500] transition-all duration-300 ease-in-out transform hover:scale-105'
				>
				  Get Started
				</button>
			  </div>
			</form>

          </>
        ) : (
          <div className='text-center relative z-10'>
            <h2 className='text-[24px] lg:text-[32px] xl:text-[48px] 3xl:text-[56px] font-semibold mb-4'>
              Thank You!
            </h2>
            <p className='text-[18px] xl:text-[20px] 3xl:text-[22px]'>
              Thank you for your interest in MediKan! Your request has been received, and a member of our team will reach out soon to schedule your demo. We’re excited to show you how MediKan can transform your clinic’s experience!
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BookDemo;
