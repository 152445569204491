import React from 'react'
import { useNavigate } from 'react-router-dom'
import arrow from '../../assets/home/arrowUpRight.png'
import { BlogTitle } from '../BlogDetails/BlogTitle'

const SingleRelatedCard = ({ blog }) => {
  const navigate = useNavigate()
  return (
    <div
      className='relative cursor-pointer'
      data-aos='fade-up'
      onClick={() => navigate(`/blog-details/${blog.id}`)}
    >
      <img
        src={blog.image}
        alt='How Cutting-Edge Hospital Management'
        className='w-full h-[340px] md:h-[290px] xl:h-[340px] object-cover rounded-3xl'
      />
      <div className='absolute bottom-4 left-4 bg-white px-3 py-1 rounded-full text-md font-[600]'>
        {blog.date}
      </div>
      <BlogTitle texts={blog.title}/>
      <div className='absolute rounded-3xl top-0 right-0 left-0 bottom-0 hover:bg-black hover:bg-opacity-25 cursor-pointer' />
      <div
        className='absolute top-4 right-4  bg-white rounded-full p-1 cursor-pointer'
        onClick={() => navigate('/blog-details/1')}
      >
        <img src={arrow} alt='Arrow icon' className='w-[35px]' />
      </div>
    </div>
  )
}

export default SingleRelatedCard
