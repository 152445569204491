import React from 'react'
import DetailsHeader from '../components/BlogDetails/DetailsHeader'
import BlogContent from '../components/BlogDetails/BlogContent'
import LatestBlogs from '../components/BlogDetails/LatestBlogs'

const BlogDetails = () => {
  return (
    <div>
      <DetailsHeader />
      <BlogContent />
      <LatestBlogs />
    </div>
  )
}

export default BlogDetails
