import React from 'react'
import arrowImg from '../../assets/home/arrow.png'
import card1 from '../../assets/home/Frameworks/card1.png'
import card2 from '../../assets/home/Frameworks/card2.png'
import card3 from '../../assets/home/Frameworks/card3.png'
import card4 from '../../assets/home/Frameworks/card4.png'
import frameIcon1 from '../../assets/home/Frameworks/framework1.png'
import frameIcon2 from '../../assets/home/Frameworks/framework2.png'
import frameIcon4 from '../../assets/home/Frameworks/framework4.png'
import medikanLogo from '../../assets/home/Frameworks/frameworkLogo.png'
import frameIcon3 from '../../assets/home/Frameworks/laptop-medical.png'
import SingleFramworkCard from './SingleFramworkCard'

const cards = [
  {
    backImg: card1,
    icon: frameIcon1,
    title: 'Proactive Patient Care',
    description:
      'We enable healthcare providers to anticipate patient needs, fostering early interventions and enhancing overall satisfaction.',
    aos: 'fade-up', // Animation for top cards
  },
  {
    backImg: card2,
    icon: frameIcon2,
    title: 'Patient-Centric Operations',
    description:
      'Our focus on patient-centric operations tailors every aspect of the healthcare experience, improving engagement and optimizing resource allocation.',
    aos: 'fade-up', // Animation for top cards
  },
  {
    backImg: card3,
    icon: frameIcon3,
    title: 'Tech-Enabled Workflows',
    description:
      'By streamlining workflows through technology, healthcare professionals can focus on exceptional care, boosting efficiency and reducing errors.',
    aos: 'fade-down', // Animation for bottom cards
  },
  {
    backImg: card4,
    icon: frameIcon4,
    title: 'Data-Driven Administrative Processes',
    description:
      'Data-driven processes provide actionable insights for informed decision-making, optimizing operations and enhancing patient experiences.',
    aos: 'fade-down', // Animation for bottom cards
  },
]

const IntroduceFramework = () => {
  return (
    <div id='about'>
      <div className='text-center px-4 py-16 bg-white'>
        {/* Top Section */}
        <p
          className='text-lg lg:text-[30px] 3xl:text-[36px] font-light sm:font-[400] text-gray-500 w-[80vw] leading-normal lg:leading-[40px]  mx-auto'
          data-aos='fade-up'
        >
          We are a clinic management <span className='font-bold'>SaaS</span>{' '}
          operating in the <span className='font-bold'>MENA</span> region,
          targeted to{' '}
          <span className='font-bold'>enhance clinic performance</span> by{' '}
          <span className='font-bold text-blue-600'>
            redefining patient experience
          </span>
        </p>

        {/* Arrow Image */}
        <div
          className='hidden lg:flex justify-center mt-3 mb-3 ml-[25%] xl:ml-[20%]'
          data-aos='fade-up'
        >
          <img
            src={arrowImg}
            alt='Arrow pointing to text'
            className='w-[120px] h-full'
          />
        </div>

        {/* Framework Introduction */}
        <h2
          className='text-[26px] lg:text-[44px] xl:text-[54px]  md:text-[30px]  font-[600] text-[#0B487F] mb-8 mt-4 lg:mt-0 text-center'
          data-aos='fade-up'
        >
          Introducing The <span className='text-[#F9915D]'>Px</span> Framework
        </h2>

        {/* Framework Description */}
        <p
          className='text-gray-500 text-sm sm:text-[18px] font-[400] leading-[22.5px] mx-auto w-[80vw]'
          data-aos='fade-up'
        >
          Coined by MediKan to serve the modern clinic, the Px Framework is your key to balancing exceptional patient care with optimized clinic efficiency. Px is built on the following four pillars:
        </p>
      </div>

      {/* Frameworks cards */}
      <div className='relative flex justify-center items-center'>
        {/* Centering the Medikan logo in an overlay */}
        <div className='absolute hidden lg:block left-[50%] transform -translate-x-1/2 z-10'>
          <img
            src={medikanLogo}
            alt='medikan'
            className='w-[150px] xl:w-[180px] 3xl:w-[250px]'
            data-aos='zoom-in'
          />
        </div>

        {/* Cards grid below the logo */}
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-x-20 lg:gap-y-8 px-4'>
          {cards.map((card, index) => (
            <SingleFramworkCard card={card} index={index} aos={card.aos} key={index} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default IntroduceFramework
