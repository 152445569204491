import React from 'react';

const SingleFramworkCard = ({ card, index, aos }) => {
  return (
    <div
      className='w-full h-auto max-w-[90vw] aspect-[3/2] md:max-w-[600px] md:aspect-[3/2] lg:max-w-[480px] lg:aspect-[3/2] xl:max-w-[560px] xl:aspect-[3/2] 3xl:max-w-[726px] 3xl:aspect-[3/2] object-fill bg-contain bg-no-repeat bg-center rounded-lg duration-300 ease-in-out cursor-pointer overflow-hidden'
      style={{
        backgroundImage: `url(${card.backImg})`,
      }}
      data-aos={aos}
      data-aos-easing='linear'
      data-aos-duration='1500'
    >
      <div
        className={`h-full flex flex-col justify-center gap-2 ${
          (index + 1) % 2 === 0 ? 'items-end pr-8' : 'items-start pl-8'
        } p-4 relative`}
      >
        <div
          className='flex justify-center items-center w-[48px] xsm:w-[64px] lg:w-[80px] xl:w-[100px] h-[48px] xsm:h-[64px] lg:h-[80px] xl:h-[100px] bg-[#006ADD] rounded-[8px] lg:rounded-[12px] xl:rounded-[16px]'
          style={{ boxShadow: '0px 30px 48px 0px #006ADD33' }}
        >
          <img
            src={card.icon}
            alt={card.title}
            className='w-[24px] xsm:w-[32px] lg:w-[48px] xl:w-[60px] h-[24px] xsm:h-[32px] lg:h-[48px] xl:h-[60px]'
          />
        </div>
        <h3 className='text-[14px] xsm:text-[16px] sm:text-[20px] lg:text-[22px] 3xl:text-[24px] text-[#0B487F] font-bold mb-2 whitespace-nowrap overflow-hidden text-ellipsis'>
          {card.title}
        </h3>
        <p
          className={`w-[75%] text-[12px] xsm:text-[14px] sm:text-[16px] lg:text-[18px] xl:text-md 3xl:text-[20px] text-[#403F3F] ${
            (index + 1) % 2 === 0 ? 'text-end' : 'text-start'
          }`}
          style={{ lineHeight: '1.4' }} // Adjusted line height for better readability
        >
          {card.description}
        </p>
      </div>
    </div>
  );
};

export default SingleFramworkCard;
