import React from 'react'
import authorImg from '../../assets/blogHeader/authorImg.png'
import copyLinkIcon from '../../assets/blogHeader/copyIcon.png'
import fbLogo from '../../assets/blogHeader/facebookIcon.png'
import linkedInLogo from '../../assets/blogHeader/linkedInIcon.png'
import twitterLogo from '../../assets/blogHeader/twitterIcon.png'

const BlogAuthor = () => {
  return (
    <div className='flex flex-col gap-4 sm:gap-0 sm:flex-row justify-between items-center mt-10 pb-10'>
      <div className='flex items-center gap-4' data-aos='fade-up'>
        <img
          src={authorImg}
          alt='Author'
          className='w-[48px] h-[48px] rounded-full'
        />
        <div>
          <h4 className='text-[#101828] text-[16px] font-bold'>Olivia Rhye</h4>
          <p className='text-[#667085] text-[14px]'>
            Product Designer, Untitled
          </p>
        </div>
      </div>

      <div
        className='flex flex-row xsm:flex-row items-center gap-4'
        data-aos='fade-up'
      >
        <button className='hidden sm:flex  bg-white border px-3 py-2 items-center gap-2 rounded-[8px] shadow-sm hover:bg-[#FFF9F6] hover:border-[#FFF9F6]'>
          <img src={copyLinkIcon} alt='Copy Link' className='w-[18px]' />
          <span className='text-[#475467] text-[14px] font-[500]'>
            Copy link
          </span>
        </button>

        <a
          href='#'
          className='bg-white border px-2 py-2 rounded-[8px] shadow-sm hover:bg-[#FFF9F6] hover:border-[#FFF9F6]'
        >
          <img src={twitterLogo} alt='Twitter' className='w-[18px] h-[18px]' />
        </a>

        <a
          href='#'
          className='bg-white border px-2 py-2 rounded-[8px] shadow-sm hover:bg-[#FFF9F6] hover:border-[#FFF9F6]'
        >
          <img src={fbLogo} alt='Facebook' className='w-[18px] h-[18px]' />
        </a>

        <a
          href='#'
          className='bg-white border px-2 py-2 rounded-[8px] shadow-sm hover:bg-[#FFF9F6] hover:border-[#FFF9F6]'
        >
          <img
            src={linkedInLogo}
            alt='LinkedIn'
            className='w-[18px] h-[18px]'
          />
        </a>
      </div>
    </div>
  )
}

export default BlogAuthor
