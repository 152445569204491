import React from 'react'
import background from '../../assets/blogs/blogBackground.svg'
import searchIcon from '../../assets/blogs/searchIcon.png'

const BlogHeader = () => {
  return (
    <div
      className='absolute text-center  mt-[-5rem] flex flex-col justify-center items-center  w-[100vw] z-5  pt-[12rem] pb-96  bg-no-repeat object-fill bg-cover rounded-b-[64px]'
      style={{
        backgroundImage: `url(${background})`,
        backgroundPosition: 'center'
      }}
    >
      <h1 className='text-[26px] lg:text-[54px] xl:text-[64px] md:text-[30px] font-[650] text-[#0B487F] mb-6 leading-[40px] lg:leading-[80px]'>
        Articles & Blogs
      </h1>
      <p className='text-gray-500 text-[18px] font-[400] leading-[22.5px] w-[70vw] text-center mx-auto mb-10'>
        Navigating the Future of Clinic Management: Insights and Articles
      </p>

      {/* Input Field */}
      <div className='flex justify-start gap-4  bg-white  w-[85vw] rounded-[32px] md:w-[40vw] 3xl:w-[750px] shadow-md  py-3 px-4 '>
        <img className='h-5 w-5' src={searchIcon} alt='search blogs and articles' />
        <input
          type='text'
          placeholder='Search'
          className='text-gray-700 text-[16px] font-[400] focus:outline-none'
        />
      </div>
    </div>
  )
}

export default BlogHeader
