import React from 'react'

import { useNavigate } from 'react-router-dom'
import copyrightLogo from '../../assets/footer/copyrighticon.png'
import logo from '../../assets/footer/footerLogo.png'
import fbLogo from '../../assets/social/fb.png'
import instagramLogo from '../../assets/social/instagram.png'
import linkedInLogo from '../../assets/social/linkedin.png'
import { scrollToSection } from '../../utils/scroll.ts'

const Footer = () => {

  const navigate = useNavigate();
  const handleScrollNavigation = (e, sectionId, offset, page='/') => {
    e.preventDefault();
    navigate(`${page}`);   
    setTimeout(() => {
      scrollToSection(e, sectionId, offset);
    }, 100);
  };

  return (
    <div className='bg-[#0b487f] text-white rounded-t-[26.67px]'>
      <div className='flex flex-col justify-between items-center sm:items-end px-14 sm:px-36 py-10 sm:py-20 sm:flex-row'>
        <div className='flex flex-col gap-8'>
          <div className='flex justify-normal items-center gap-4'>
            <span className='text-[20px] sm:text-[32px] font-[700] mx-auto sm:mx-0'>
              made with
            </span>
            <img src={logo} alt='medikan logo' className='w-20'/>
          </div>
          {/* Hide menu items on mobile view */}
          <div className='hidden sm:flex flex-col sm:flex-row items-center gap-4 lg:gap-10 text-[14px] lg:text-[16px] font-[600] pt-4 sm:pt-0'>
            <a className='hover:text-[#F9915D]' href='#about' onClick={(e) => handleScrollNavigation(e, 'about', 80)}>About Us</a>
            <a className='hover:text-[#F9915D]' href='#why' onClick={(e) => handleScrollNavigation(e, 'why', 80)}>Why Us</a>
            <a className='hover:text-[#F9915D]' href='#faqs' onClick={(e) => handleScrollNavigation(e, 'faqs', 30)}>FAQs</a>
          </div>
        </div>
        <div className='flex items-center gap-4 mt-12 sm:mt-0'>
          <a
            href='https://www.instagram.com/medikan.mena/'
            target='_blank'
            className='bg-white text-[#0b487f] w-[40px] h-[40px] flex justify-center items-center rounded-[8px]'
          >
            <img src={instagramLogo} alt='instagram mkdikan' className='h-[27px] w-[27px]'/>
          </a>
          <a
            href='https://www.facebook.com/people/MediKan-%D9%85%D9%8A%D8%AF%D9%8A%D9%83%D8%A7%D9%86/61568138036263/'
            target='_blank'
            className='bg-white text-[#0b487f] w-[40px] h-[40px] flex justify-center items-center rounded-[8px]'
          >
            <img src={fbLogo} alt='facebook mkdikan' className='h-[27px] w-[27px]'/>
          </a>
          <a
            href='https://www.linkedin.com/company/medikan-mena'
            target='_blank'
            className='bg-white text-[#0b487f] w-[40px] h-[40px] flex justify-center items-center rounded-[8px]'
          >
            <img src={linkedInLogo} alt='linkedin mkdikan' className='h-[27px] w-[27px]'/>
          </a>
        </div>
      </div>
      <div className='flex justify-center items-center gap-2 py-4'>
        <img
          src={copyrightLogo}
          alt='medikan copyright'
          className='w-[80px] sm:w-100'
        />
        <span className='text-[14px] sm:text-[16px]'>
          © 2024 . <a className='hover:text-[#F9915D]' href='#'>Terms & Conditions</a> . <a className='hover:text-[#F9915D]' href='#'>Privacy Policy</a>
        </span>
      </div>
    </div>
  )
}

export default Footer
