import { gsap } from 'gsap';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import headerImg1 from '../../assets/home/header11.png';
import headerImg2 from '../../assets/home/header2.png';
import headerImg3 from '../../assets/home/header3.png';
import logo from '../../assets/nav/medikanLogo1.png';
import { scrollToSection } from '../../utils/scroll.ts';

const Header = () => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const words = ['SIMPLE', 'EFFICIENT', 'EFFORTLESS', 'LOGO'];
  const wordRef = useRef(null);

  const navigate = useNavigate();
  const handleScrollNavigation = (e, sectionId, offset, page='/') => {
    e.preventDefault();
    navigate(`${page}`);   
    setTimeout(() => {
      scrollToSection(e, sectionId, offset);
    }, 100);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex(prevIndex => (prevIndex + 1) % words.length);
    }, currentWordIndex === 3 ? 5000 : 2000);

    return () => clearInterval(interval);
  }, [currentWordIndex]);

  useEffect(() => {
    gsap.fromTo(wordRef.current, { y: 40, opacity: 0 }, { y: 0, opacity: 1 });
  }, [currentWordIndex]);

  const currentWord = words[currentWordIndex];

  return (
    <div id='hero' className='bg-[#D7ECFF] rounded-b-[32px] text-center overflow-hidden pt-[6rem] xl:pt-[12rem] lg:pt-[10rem]  md:pt-[8rem]  sm:pt-[8rem] mt-[-5rem] pb-12 sm:pb-0'>
      <div className='flex flex-col justify-center items-center'>
        {/* Main Text */}
        <h1 className='text-[26px] md:text-[28px] lg:text-[36px] xl:text-[52px] font-[650] text-[#0B487F] z-30 leading-[40px] lg:leading-[80px]'>
          CLINIC MANAGEMENT MADE
          <span
            ref={wordRef}
            className='h-[60px] sm:h-100 flex flex-col justify-center items-center'
          >
            {currentWord === 'LOGO' ? (
              <img src={logo} alt='Logo' className='w-32 sm:w-60' />
            ) : (
              currentWord
            )}
          </span>
        </h1>

        {/* Button Container */}
        <div className='flex justify-center my-0 mb-4 sm:my-4 sm:mb-4 md:mb-4 lg:mb-0 py-4 z-10'>
          <a
            href='#book'
            onClick={(e) => handleScrollNavigation(e, 'book', 200)}
            className='text-[10px] md:text-[16px] font-[500] md:font-[700] text-white bg-[#F9915D] rounded-[12px] md:rounded-[14px] px-[24px] py-[10px] md:py-[12px] cursor-pointer transition-all duration-300 ease-in-out transform hover:bg-[#c76a3e] hover:scale-105'
          >
            Get started
          </a>
        </div>

        {/* Images Container */}
        <div className='relative w-full max-w-[80vw] sm:max-w-[80vw] mx-auto flex justify-center items-center -mt-[150px] xl:-mt-[275px] lg:-mt-[250px]  md:-mt-[200px] -z-0'>
          <img
            src={headerImg2}
            alt='Left Side Dashboard View'
            className='absolute left-[-20px] sm:left-[-50px] bottom-[-100px] xl:bottom-[-100px] w-[200px] sm:[150px] md:w-[300px] xl:w-[500px] lg:w-[300px] z-10'
          />
          <img
            src={headerImg1}
            alt='Clinic Management Dashboard'
            className='z-20 w-full max-w-[1200px] mx-auto relative bottom-[-90px] lg:bottom-[-230px] md:bottom-[-160px] sm:bottom-[-110px] mt-10 md:mt-2 sm:mt-0'
          />
          <img
            src={headerImg3}
            alt='Right Side Dashboard View'
            className='absolute right-[-20px] sm:right-[-50px] bottom-[-100px] xl:bottom-[-100px] w-[200px] sm:w-[150px] md:w-[300px]  xl:w-[500px] lg:w-[300px] z-10'
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
