import React from 'react'
import Header from '../components/Home/Header'
import IntroduceFramework from '../components/Home/IntroduceFramework'

import ClinicPerformance from '../components/Home/ClinicPerformance'

import BookDemo from '../components/Home/BookDemo'
import Faq from '../components/Home/Faq'


const Home = () => {
  return (
    <div>
      <Header />
      <IntroduceFramework />
      <ClinicPerformance />
      <BookDemo />
      <Faq />
    </div>
  )
}

export default Home
