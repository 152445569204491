import React from 'react'
import { useNavigate } from 'react-router-dom'
import blog5 from '../../assets/blogs/blog5Image.jpg'
import blog6 from '../../assets/blogs/blog6Image.jpeg'
import blog7 from '../../assets/blogs/blog7Image.jpg'
import blog8 from '../../assets/blogs/blog8Image.jpg'
import blog9 from '../../assets/blogs/blog9Image.jpeg'
import arrow from '../../assets/home/arrowUpRight.png'
import blog1 from '../../assets/home/blog1Image.jpeg'
import blog2 from '../../assets/home/blog2Image.jpeg'
import blog3 from '../../assets/home/blog3Image.jpeg'
import { BlogTitle } from '../BlogDetails/BlogTitle'
import SingleRelatedCard from './SingleRelatedCard'

const relatedBlogs1 = [
  {
    id: 5,
    title: ['How Cutting-Edge Hospital', 'Management'],
    date: 'Sep 15, 2024',
    image: blog5
  },
  {
    id: 6,
    title: ['How Cutting-Edge Hospital', 'Management'],
    date: 'Sep 15, 2024',
    image: blog6
  },
  {
    id: 7,
    title: ['How Cutting-Edge Hospital', 'Management'],
    date: 'Sep 15, 2024',
    image: blog7
  }
]

const relatedBlogs2 = [
  {
    id: 8,
    title: ['How Cutting-Edge Hospital', 'Management'],
    date: 'Sep 15, 2024',
    image: blog8
  },
  {
    id: 9,
    title: ['How Cutting-Edge Hospital', 'Management'],
    date: 'Sep 15, 2024',
    image: blog9
  }
]

const BlogCards = () => {
  const navigate = useNavigate()
  return (
    <div className='relative z-10 mt-96'>
      <div className='w-[80vw] xl:w-[60vw]  mx-auto'>
        <div className='flex flex-col md:flex-row justify-between items-center gap-4'>
          <div
            className=' w-[85vw] md:w-[60%] lg:w-[80%] h-[350px] sm:h-[500px] 3xl:h-[600px] rounded-xl overflow-hidden  cursor-pointer'
            data-aos='fade-up'
            onClick={() => navigate('/blog-details/1')}
          >
            <img
              src={blog1}
              alt='Blog'
              className='w-full h-full object-cover'
            />
            <div className='absolute bottom-4 left-4 bg-white px-3 py-1 rounded-full text-lg font-[600]'>
              Sep 15, 2024
            </div>
            <div className='absolute top-4 left-4 flex flex-col text-black w-full'>
              <p><span className=' text-[16px] pr-4 sm:text-[24px] xl:text-[28px] font-semibold py-1 px-2 rounded-tr-xl rounded-br-xl rounded-tl-lg bg-white'>
              How Cutting-Edge Hospital
            </span>
            </p>
            <p><span className='custom-span pr-4 text-[16px] sm:text-[24px] xl:text-[28px] font-semibold py-1 px-2 rounded-tr-xl rounded-br-xl bg-white'>
              Management Sytems are 
            </span>
            </p>
            <p><span className='custom-span pr-4 text-[16px] sm:text-[24px] xl:text-[28px] font-semibold py-1 px-2 rounded-tr-xl rounded-br-xl bg-white'>
              Transferring Patient
            </span>
            </p>
            <p><span className='custom-span pr-4 sm:custom-span text-[16px] sm:text-[24px] xl:text-[28px] font-semibold py-1 px-2 rounded-tr-xl rounded-br-xl bg-white'>
              Care
            </span>
            </p>
            {/* <span className='custom-span text-[16px] sm:text-[24px] xl:text-[28px] font-semibold py-1 px-2 rounded-br-xl  bg-white w-[50%]'>
              Systems are Transforming
            </span>
            <span className='custom-span text-[16px] sm:text-[24px] xl:text-[28px] font-semibold py-1 px-2 rounded-br-xl rounded-bl-lg bg-white  w-[35%]'>
              Patient Care
            </span> */}
            </div>
            <div className='absolute rounded-xl top-0 right-0 left-0 bottom-0 hover:bg-black hover:bg-opacity-25 cursor-pointer' />
            <div className='absolute top-4 right-4 bg-white rounded-full p-1 sm:p-3 cursor-pointer'>
              <img src={arrow} alt='Arrow Icon' className='w-10 h-10' />
            </div>
          </div>
          <div
            className=' w-[85vw] md:w-[40%] lg:w-[50%] h-[350px] sm:h-[500px] 3xl:h-[600px] cursor-pointer'
            data-aos='fade-up'
            onClick={() => navigate('/blog-details/1')}
          >
            <img
              src={blog2}
              alt='How Cutting-Edge Hospital Management'
              className='w-full h-full object-cover rounded-xl'
            />
            <div className='absolute bottom-4 left-4 bg-white px-3 py-1 rounded-full text-md font-[600]'>
              Sep 15, 2024
            </div>
            <BlogTitle texts={['How Cutting-Edge Hospital', 'Management']}/>
            <div className='absolute rounded-xl top-0 right-0 left-0 bottom-0 hover:bg-black hover:bg-opacity-25 cursor-pointer' />
            <div className='absolute top-4 right-4  bg-white rounded-full p-1'>
              <img src={arrow} alt='Arrow icon' className='w-[35px]' />
            </div>
          </div>
        </div>

        {/* Related Blogs */}
        <div className='grid grid-cols-1 md:grid-cols-3 gap-6 mt-6'>
          {/* Smaller Card */}
          <div
            className=' md:col-span-1 cursor-pointer'
            data-aos='fade-up'
            onClick={() => navigate('/blog-details/1')}
          >
            <img
              src={blog2}
              alt='How Cutting-Edge Hospital Management'
              className='w-full h-[300px] md:h-[250px] xl:h-[350px] object-cover  rounded-xl'
            />
            <div className='absolute bottom-4 left-4 bg-white px-3 py-1 rounded-full text-md font-[600]'>
              Sep 15, 2024
            </div>
            <BlogTitle texts={['How Cutting-Edge Hospital', 'Management']}/>
            <div className='absolute rounded-xl top-0 right-0 left-0 bottom-0 hover:bg-black hover:bg-opacity-25 cursor-pointer' />
            <div className='absolute top-4 right-4 bg-white rounded-full p-1  cursor-pointer'>
              <img src={arrow} alt='Arrow icon' className='w-[35px]' />
            </div>
          </div>
          {/* Large Card */}
          <div
            className=' md:col-span-2  cursor-pointer'
            data-aos='fade-up'
            onClick={() => navigate('/blog-details/1')}
          >
            {/* bugs */}
            <img
              src={blog3}
              alt='How Cutting-Edge Hospital Management'
              className='w-full h-[300px] md:h-[250px] xl:h-[350px] object-fill rounded-xl'
            />
            <div className='absolute bottom-4 left-4 bg-white px-3 py-1 rounded-full text-md font-[600]'>
              Sep 15, 2024
            </div>
            <BlogTitle texts={['How Cutting-Edge Hospital', 'Management']}/>
            <div className='absolute top-4 right-4 bg-white rounded-full p-1  cursor-pointer'>
              <img
                src={arrow}
                alt='Arrow icon'
                className='w-[35px]'
                onClick={() => navigate('/blog-details/1')}
              />
            </div>
          </div>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-6 mt-6'>
          {relatedBlogs1 &&
            relatedBlogs1.map((blog, index) => (
              <SingleRelatedCard key={index} blog={blog} />
            ))}
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-6 mt-6'>
          {relatedBlogs2 &&
            relatedBlogs2.map((blog, index) => (
              <SingleRelatedCard key={index} blog={blog} />
            ))}
        </div>
      </div>
      <div className='text-center mt-10 pb-10' data-aos='fade-up'>
        <button className='bg-[#F9915D] hover:bg-[#c76a3e] text-white py-3 px-8 rounded-[16px] text-[18px] font-[700] transition-all duration-300 ease-in-out transform hover:scale-105'>
          Load more
        </button>
      </div>
    </div>
  )
}

export default BlogCards
