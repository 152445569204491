import React from 'react'
import BlogHeader from '../components/Blogs/BlogHeader'
import BlogCards from '../components/Blogs/BlogCards'

const Blogs = () => {
  return (
    <>
      <BlogHeader />
      <BlogCards />
    </>
  )
}

export default Blogs
